<template>
  <div
    class="flex flex-col items-center min-h-screen justify-center text-a-neutral font-sans tracking-wide"
  >
    <div
      class="flex flex-col max-w-sm w-full"
    >
      <LogoSwiper />
      <!-- Login Alert -->
      <BaseAlert
        animation="fade"
        :isRounded="true"
      />
      <!-- Login Alert Ends -->
      <slot> </slot>
    </div>
  </div>
</template>

<script setup></script>

<style></style>
